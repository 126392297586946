// OslerBlockViewer.jsx
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { useCreateBlockNote } from "@blocknote/react";

export default function OslerBlockViewer({ content }) {
    const editor = useCreateBlockNote({
        initialContent: content || [{ id: "block1", type: "paragraph", content: [] }], // Conteúdo inicial ou vazio
    });

    return (
        <BlockNoteView
            editor={editor}
            editable={false} // Modo somente leitura
        />
    );
}