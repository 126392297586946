import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AequanimitasController from './AequanimitasController'
import VideosList from './VideosList'
import VideoEditor from './VideoEditor'


import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase/firebase-setup'


// Styled Components
const DashboardContainer = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
`

const MainContent = styled.div`
    flex: 1;
    padding: 20px;
    min-width: 500px;
    overflow-y: auto;
`

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #757575;
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
`

const LoadingMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #616161;
`

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #d32f2f;
    text-align: center;
    padding: 0 20px;
`

export default function AequanimitasDashboard() {
    const [videos, setVideos] = useState([])
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const user = useSelector(state => state.user.data)
    const navigate = useNavigate()






    useEffect(() => {
        const loadVideos = async () => {

            try {
                const authorizedUserIds = ['5TkhVQXzmaXBGaGC3sA1mT0upsq1', 'UVk85cjiR8Y6DVjL4C2C5pd32LJ3', 'BCb21hdF9vMOLFwDXgPPlfXEHpN2'];
            
                // Check if current user's ID is in the authorized list
                if (!authorizedUserIds.includes(user.id)) {
                    console.log('Missing permission');
                    navigate('/app');
                    return;
                }
                
                const result = await AequanimitasController.fetchAllVideos()
                
                if (result.success) {
                    setVideos(result.data)
                } else {
                    setError(result.error)
                }
                
                setLoading(false)
            } catch (err) {
                console.error('Error in AequanimitasDashboard:', err)
                setError('An unexpected error occurred')
                setLoading(false)
            }
        }

        
        loadVideos()
    }, [user.id, navigate])

    const handleSelectVideo = async (videoId) => {
        if (unsavedChanges && !window.confirm('You have unsaved changes. Discard them?')) {
            return
        }
        
        try {
            setSelectedVideo(null)
            setLoading(true)
            
            const result = await AequanimitasController.getVideoById(videoId)
            
            if (result.success) {
                setSelectedVideo(result.data)
                setUnsavedChanges(false)
            } else {
                setError(result.error)
            }
            
            setLoading(false)
        } catch (err) {
            console.error('Error selecting video:', err)
            setError('Failed to load selected video')
            setLoading(false)
        }
    }

    const handleCreateNew = () => {
        if (unsavedChanges && !window.confirm('You have unsaved changes. Discard them?')) {
            return
        }
        
        // Reset state first
        setSelectedVideo(null)
        
        // Create a new video template
        const newVideo = {
            videoId: '',
            title: 'Untitled Video',
            description: '',
            created_at: new Date(),
            updated_at: new Date()
        }
        
        setSelectedVideo(newVideo)
        setUnsavedChanges(false)
    }

    const handleVideoFieldsChange = (field, value) => {
        setSelectedVideo({ ...selectedVideo, [field]: value })
        setUnsavedChanges(true)
    }

    const handlePublish = async () => {
        if (!selectedVideo) return
        
        try {
            setLoading(true)
            
            const result = await AequanimitasController.publishVideo(selectedVideo, videos)
            
            if (result.success) {
                setVideos(result.data.videos)
                setSelectedVideo(result.data.selectedVideo)
                setUnsavedChanges(false)
            } else {
                setError(result.error)
            }
            
            setLoading(false)
        } catch (err) {
            console.error('Error publishing video:', err)
            setError('Failed to publish video')
            setLoading(false)
        }
    }

    const handleDeleteClick = () => {
        if (!selectedVideo || !selectedVideo.id) return
        setShowDeleteDialog(true)
    }

    const handleDeleteConfirm = async () => {
        if (!selectedVideo || !selectedVideo.id) return
        
        try {
            setLoading(true)
            
            const result = await AequanimitasController.deleteVideo(selectedVideo.id, videos)
            
            if (result.success) {
                setShowDeleteDialog(false)
                setVideos(result.data.videos)
                setSelectedVideo(null)
                setUnsavedChanges(false)
            } else {
                setError(result.error)
                setShowDeleteDialog(false)
            }
            
            setLoading(false)
        } catch (err) {
            console.error('Error deleting video:', err)
            setError('Failed to delete video')
            setLoading(false)
            setShowDeleteDialog(false)
        }
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    if (loading && videos.length === 0) {
        return <LoadingMessage>Loading videos...</LoadingMessage>
    }

    if (error && videos.length === 0) {
        return <ErrorMessage>{error}</ErrorMessage>
    }

    return (
        <DashboardContainer>
            <VideosList 
                videos={videos}
                selectedVideo={selectedVideo}
                onSelectVideo={handleSelectVideo}
                onCreateNew={handleCreateNew}
            />
            <MainContent>
                {selectedVideo ? (
                    <VideoEditor
                        selectedVideo={selectedVideo}
                        unsavedChanges={unsavedChanges}
                        showDeleteDialog={showDeleteDialog}
                        onVideoFieldsChange={handleVideoFieldsChange}
                        onPublish={handlePublish}
                        onDeleteClick={handleDeleteClick}
                        onDeleteConfirm={handleDeleteConfirm}
                        onDeleteCancel={handleDeleteCancel}
                    />
                ) : (
                    <EmptyState>
                        {loading ? "Loading editor..." : "Select a video to edit or create a new one"}
                    </EmptyState>
                )}
            </MainContent>
        </DashboardContainer>
    )
}