import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { db } from '../firebase/firebase-setup';


// Componentes estilizados - versão elegante e sofisticada
const FeedbackContainer = styled.div`
    margin: 16px 0;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    font-size: 13px;
    border: 1px solid #f0f0f0;
    
    @media (max-width: 768px) {
        padding: 16px;
        margin: 12px 0;
        font-size: 12px;
    }
`;

const FeedbackTitle = styled.h3`
    font-size: 14px;
    margin-bottom: 16px;
    color: #1a1a1a;
    font-weight: 500;
    letter-spacing: 0.2px;
    
    @media (max-width: 768px) {
        font-size: 13px;
        margin-bottom: 14px;
    }
`;

const FeedbackButton = styled.button`
    background-color: #212529;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    letter-spacing: 0.3px;
    font-weight: 500;
    
    &:hover {
        background-color: #343a40;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    &:disabled {
        background-color: #e9ecef;
        color: #868e96;
        cursor: not-allowed;
        transform: none;
        box-shadow: none;
    }
    
    @media (max-width: 768px) {
        font-size: 11px;
        padding: 7px 14px;
    }
`;

const RatingContainer = styled.div`
    margin: 14px 0;
`;

const RatingOptions = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 8px;
    
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 6px;
    }
`;

// Função para determinar as cores dos botões
const getButtonStyle = (index, selected) => {
    // Paleta elegante de cores
    const colors = {
        0: { // Pouco
            bg: '#f8f9fa',
            hover: '#ffe3e3',
            selected: '#e03131'
        },
        1: { // Normal
            bg: '#f8f9fa',
            hover: '#e3f2fd',
            selected: '#1864ab'
        },
        2: { // Muito
            bg: '#f8f9fa',
            hover: '#d3f9d8',
            selected: '#087f5b'
        }
    };
    
    return {
        background: selected ? colors[index].selected : colors[index].bg,
        hover: colors[index].hover,
        color: selected ? 'white' : '#212529',
        borderColor: selected ? colors[index].selected : '#dee2e6'
    };
};

const RatingButton = styled.button`
    flex: 1;
    padding: 9px;
    border: 1px solid ${props => props.selected ? 
        getButtonStyle(props.index, true).borderColor : 
        getButtonStyle(props.index, false).borderColor};
    background-color: ${props => props.selected ? 
        getButtonStyle(props.index, true).background : 
        getButtonStyle(props.index, false).background};
    color: ${props => props.selected ? 'white' : '#212529'};
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: ${props => props.selected ? '500' : 'normal'};
    
    &:hover {
        background-color: ${props => props.selected ? 
            getButtonStyle(props.index, true).background : 
            getButtonStyle(props.index, false).hover};
        border-color: ${props => props.selected ? 
            getButtonStyle(props.index, true).borderColor : 
            getButtonStyle(props.index, false).borderColor};
        transform: translateY(-1px);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    @media (max-width: 768px) {
        font-size: 11px;
        padding: 8px 6px;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    min-height: 80px;
    margin-top: 8px;
    font-family: inherit;
    resize: vertical;
    font-size: 12px;
    color: #212529;
    transition: border-color 0.2s ease;
    
    &:focus {
        border-color: #adb5bd;
        outline: none;
    }
    
    &::placeholder {
        color: #adb5bd;
    }
    
    @media (max-width: 768px) {
        min-height: 70px;
        font-size: 11px;
        padding: 10px;
    }
`;

const ThankYouMessage = styled.div`
    text-align: center;
    padding: 16px;
    font-size: 13px;
    color: #212529;
    line-height: 1.5;
    
    p {
        margin-top: 8px;
        color: #495057;
    }
    
    @media (max-width: 768px) {
        padding: 12px;
        font-size: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    
    @media (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 8px;
        
        & > button {
            width: 100%;
        }
    }
`;




// Mensagens para os títulos de cada etapa
const stepTitles = {
    0: "Gostaria de avaliar esta aula?",
    1: "Qual sua avaliação geral para esta aula?",
    2: "Quanto interesse você teria em outra aula com este professor?",
    3: "Quanto interesse você teria em outra aula deste tema, com outro mentor?",
    4: "Algum comentário adicional sobre a aula?",
    5: "Obrigado pelo seu feedback!"
};

// Opções para os ratings
const ratingOptions = ["Pouco", "Normal", "Muito"];

export default function ClassFeedback({classId, classTitle}) {
    // Estado para controlar a etapa atual do feedback
    const [step, setStep] = useState(0);
    
    // Estados para armazenar as respostas
    const [generalRating, setGeneralRating] = useState(null);
    const [professorInterest, setProfessorInterest] = useState(null);
    const [topicInterest, setTopicInterest] = useState(null);
    const [comments, setComments] = useState("");

    const user = useSelector(state => state.user.data)
    
    // Função para iniciar o processo de feedback
    const startFeedback = () => {
        setStep(1);
    };
    
    // Função para ir para a próxima etapa
    const nextStep = () => {
        setStep(prev => prev + 1);
    };
    
    // Função para voltar uma etapa
    const prevStep = () => {
        setStep(prev => prev - 1);
    };
    
    // Função para selecionar um rating
    const selectRating = (step, value) => {
        if (step === 1) {
            setGeneralRating(value);
        } else if (step === 2) {
            setProfessorInterest(value);
        } else if (step === 3) {
            setTopicInterest(value);
        }
    };
    
    // Função para lidar com a mudança no textarea
    const handleCommentChange = (event) => {
        setComments(event.target.value);
    };
    
    // Função para submeter o feedback
    const submitFeedback = () => {

        const userId = user.id

        const feedbackData = {
            userId,
            classId,
            classTitle,
            generalRating,
            professorInterest,
            topicInterest,
            comments,
            feedbackDate: new Date().toISOString()
        };
        

        db.collection(`aequanimitas_feedback`).add(feedbackData)
        
        // Registro no console para debug
        console.log("Feedback enviado:");
        console.log(feedbackData);
        
        // Avança para a etapa de agradecimento
        nextStep();
    };
    
    // Renderização condicional baseada na etapa atual
    const renderStep = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <FeedbackTitle>{stepTitles[step]}</FeedbackTitle>
                        <div style={{ textAlign: 'center' }}>
                            <FeedbackButton onClick={startFeedback}>
                                Quero avaliar esta aula
                            </FeedbackButton>
                        </div>
                    </>
                );
                
            case 1:
            case 2:
            case 3:
                return (
                    <>
                        <FeedbackTitle>{stepTitles[step]}</FeedbackTitle>
                        <RatingContainer>
                            <RatingOptions>
                                {ratingOptions.map((option, index) => (
                                    <RatingButton 
                                        key={index}
                                        index={index}
                                        selected={
                                            step === 1 ? generalRating === index :
                                            step === 2 ? professorInterest === index :
                                            topicInterest === index
                                        }
                                        onClick={() => selectRating(step, index)}
                                    >
                                        {option}
                                    </RatingButton>
                                ))}
                            </RatingOptions>
                        </RatingContainer>
                        <ButtonContainer>
                            <FeedbackButton onClick={prevStep}>
                                Voltar
                            </FeedbackButton>
                            <FeedbackButton 
                                onClick={nextStep}
                                disabled={
                                    step === 1 && generalRating === null ||
                                    step === 2 && professorInterest === null ||
                                    step === 3 && topicInterest === null
                                }
                            >
                                Próximo
                            </FeedbackButton>
                        </ButtonContainer>
                    </>
                );
                
            case 4:
                return (
                    <>
                        <FeedbackTitle>{stepTitles[step]}</FeedbackTitle>
                        <TextArea 
                            value={comments}
                            onChange={handleCommentChange}
                            placeholder="Compartilhe sua opinião sobre a aula..."
                        />
                        <ButtonContainer>
                            <FeedbackButton onClick={prevStep}>
                                Voltar
                            </FeedbackButton>
                            <FeedbackButton onClick={submitFeedback}>
                                Enviar avaliação
                            </FeedbackButton>
                        </ButtonContainer>
                    </>
                );
                
            case 5:
                return (
                    <ThankYouMessage>
                        <FeedbackTitle>{stepTitles[step]}</FeedbackTitle>
                        <p>Sua avaliação é muito importante para melhorarmos nosso conteúdo.</p>
                    </ThankYouMessage>
                );
                
            default:
                return null;
        }
    };
    
    return (
        <FeedbackContainer>
            {renderStep()}
        </FeedbackContainer>
    );
}