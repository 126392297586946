import React, { useState, useEffect } from 'react'
import Class from './Class'
import styled from 'styled-components'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase/firebase-setup'

const ScreenWrapper = styled.div`
    min-height: 100%;
    width: 100%;
`

const ClassesContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
`

const LoadingText = styled.div`
    text-align: center;
    font-size: 14px;
    color: #888;
    padding: 40px 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
`

const AccessDeniedMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: #ff4444;
    padding: 20px;
    background: #ffe6e6;
    border-radius: 8px;
    margin: 20px 0;
`

const ErrorMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: #ff4444;
    padding: 20px;
    background: #ffe6e6;
    border-radius: 8px;
    margin: 20px 0;
`

export default function AequanimitasScreen({ hasAccess = false }) {
    const [classes, setClasses] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    useEffect(() => {
        async function fetchClasses() {
            if (!hasAccess) {
                setLoading(false)
                return
            }
            
            try {
                // Buscar os vídeos do Firebase ordenados por data de criação (mais recentes primeiro)
                const classesCollection = collection(db, 'aequanimitas')
                const classesQuery = query(
                    classesCollection, 
                    orderBy('created_at', 'desc')  // Ordenação decrescente para mostrar os mais recentes primeiro
                )
                const querySnapshot = await getDocs(classesQuery)
                
                // Transformar os documentos em um array de objetos
                const classesData = querySnapshot.docs.map(doc => {
                    const data = doc.data()
                    
                    // Converter a descrição em texto para JSX com parágrafos
                    const descriptionJSX = data.description
                        .split('\n\n')
                        .filter(paragraph => paragraph.trim() !== '')
                        .map((paragraph, i) => {
                            // Verificar se contém links e formatar adequadamente
                            if (paragraph.includes('http') || paragraph.includes('www')) {
                                // Implementação simplificada - em produção seria mais complexa
                                const parts = paragraph.split(/(https?:\/\/[^\s]+)/)
                                return (
                                    <p key={i}>
                                        {parts.map((part, j) => 
                                            part.startsWith('http') ? 
                                                <a key={j} href={part} target="_blank" rel="noopener noreferrer">
                                                    {part}
                                                </a> : 
                                                part
                                        )}
                                    </p>
                                )
                            }
                            return <p key={i}>{paragraph}</p>
                        })
                    
                    return {
                        id: doc.id,
                        videoId: data.videoId,
                        title: data.title,
                        description: <>{descriptionJSX}</>,
                        createdAt: data.created_at instanceof Date 
                            ? data.created_at 
                            : data.created_at.toDate()
                    }
                })
                
                // Ordenar novamente os dados após o processamento (garantia adicional)
                const sortedClassesData = classesData.sort((a, b) => b.createdAt - a.createdAt)
                
                setClasses(sortedClassesData)
                setError(null)
            } catch (err) {
                console.error('Error fetching classes:', err)
                setError('Não foi possível carregar as aulas. Por favor, tente novamente mais tarde.')
            } finally {
                setLoading(false)
            }
        }

        fetchClasses()
    }, [hasAccess])

    return (
        <ScreenWrapper>
            {!hasAccess && (
                <AccessDeniedMessage>
                    Você não tem acesso a este conteúdo. Por favor, assine um plano para assistir às aulas.
                </AccessDeniedMessage>
            )}
            
            {hasAccess && loading ? (
                <LoadingText>Carregando...</LoadingText>
            ) : hasAccess && error ? (
                <ErrorMessage>{error}</ErrorMessage>
            ) : hasAccess && classes.length === 0 ? (
                <LoadingText>Nenhuma aula disponível no momento.</LoadingText>
            ) : hasAccess && classes.length > 0 ? (
                <ClassesContainer>
                    {classes.map((classItem) => (
                        <Class
                            key={classItem.id}
                            videoId={classItem.videoId}
                            title={classItem.title}
                            description={classItem.description}
                        />
                    ))}
                </ClassesContainer>
            ) : null}
        </ScreenWrapper>
    )
}