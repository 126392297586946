import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

// Styled Components
const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #f9f9f9;
`

const Title = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
`

const ButtonsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    max-width: 800px;
    width: 100%;
`

const DashboardButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 12px;
    border: none;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }
    
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.3), 0 8px 20px rgba(0, 0, 0, 0.15);
    }
`

const ButtonEmoji = styled.span`
    font-size: 4rem;
    margin-bottom: 16px;
`

const ButtonTitle = styled.h2`
    font-size: 1.5rem;
    margin: 0 0 8px 0;
    color: #333;
`

const ButtonDescription = styled.p`
    font-size: 1rem;
    color: #666;
    margin: 0;
    text-align: center;
`

export default function Dashboard() {
    const navigate = useNavigate()
    
    const handleNavigateToBibliotheca = () => {
        navigate('/dashboard-bibliotheca')
    }
    
    const handleNavigateToAequanimitas = () => {
        navigate('/dashboard-aequanimitas')
    }

    return (
        <DashboardContainer>
            <Title>Dashboard</Title>
            <ButtonsGrid>
                <DashboardButton onClick={handleNavigateToBibliotheca}>
                    <ButtonEmoji>📚</ButtonEmoji>
                    <ButtonTitle>Bibliotheca</ButtonTitle>
                    <ButtonDescription>
                        Manage blog texts and publications
                    </ButtonDescription>
                </DashboardButton>
                
                <DashboardButton onClick={handleNavigateToAequanimitas}>
                    <ButtonEmoji>🎬</ButtonEmoji>
                    <ButtonTitle>Aequanimitas</ButtonTitle>
                    <ButtonDescription>
                        Manage mentorship videos and content
                    </ButtonDescription>
                </DashboardButton>
            </ButtonsGrid>
        </DashboardContainer>
    )
}