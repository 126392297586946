import { collection, getDocs, query, orderBy, where, doc, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase/firebase-setup'

class BibliothecaController {
    async fetchAllTexts() {
        try {
            const textsCollection = collection(db, 'bibliotheca')
            const textsSnapshot = await getDocs(
                query(textsCollection, orderBy('updated_at', 'desc'))
            )
            const textsList = textsSnapshot.docs.map(doc => {
                // Manter os dados originais sem conversão
                // Deixe o TextsPanel lidar com a conversão se necessário
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
            return { success: true, data: textsList }
        } catch (err) {
            console.error('Error fetching texts:', err)
            return { success: false, error: 'Failed to load texts. Please try again later.' }
        }
    }

    async fetchTextsByTheme(theme) {
        try {
            const textsCollection = collection(db, 'bibliotheca')
            const q = query(
                textsCollection,
                where('theme', '==', theme)
            )
            const textsSnapshot = await getDocs(q)
            const textsList = textsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            return { success: true, data: textsList }
        } catch (err) {
            console.error('Error fetching texts by theme:', err)
            return { success: false, error: 'Failed to load texts for this theme. Please try again later.' }
        }
    }

    async fetchPriorityTexts() {
        try {
            const textsCollection = collection(db, 'bibliotheca')
            const q = query(
                textsCollection,
                where('isPriority', '==', true)
            )
            const textsSnapshot = await getDocs(q)
            const textsList = textsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            return { success: true, data: textsList }
        } catch (err) {
            console.error('Error fetching priority texts:', err)
            return { success: false, error: 'Failed to load priority texts. Please try again later.' }
        }
    }

    async fetchRecentTexts(limit = 5) {
        try {
            const textsCollection = collection(db, 'bibliotheca')
            const q = query(
                textsCollection,
                orderBy('updated_at', 'desc')
            )
            const textsSnapshot = await getDocs(q)
            const textsList = textsSnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                .slice(0, limit)
            return { success: true, data: textsList }
        } catch (err) {
            console.error('Error fetching recent texts:', err)
            return { success: false, error: 'Failed to load recent texts. Please try again later.' }
        }
    }

    async getTextById(textId) {
        try {
            console.log('Fetching text with ID:', textId)
            const textRef = doc(db, 'bibliotheca', textId)
            const textSnapshot = await getDoc(textRef)
            
            if (!textSnapshot.exists()) {
                console.error('Text document not found:', textId)
                return { success: false, error: 'Text not found' }
            }
            
            const textData = {
                id: textSnapshot.id,
                ...textSnapshot.data()
            }
            
            console.log('Text data loaded:', textData)
            
            // Verifica se existe um campo content no documento
            let parsedContent = []
            if (textData.content) {
                console.log('Content found for text:', textId)
                // Verificar se o conteúdo é uma string JSON e convertê-lo para um objeto
                if (typeof textData.content === 'string') {
                    try {
                        parsedContent = JSON.parse(textData.content)
                        console.log('Successfully parsed content from JSON string')
                    } catch (parseErr) {
                        console.error('Error parsing content JSON:', parseErr)
                        // Se não conseguir fazer o parse, usar um array vazio
                        parsedContent = []
                    }
                } else if (Array.isArray(textData.content)) {
                    // Se já for um array, usá-lo diretamente
                    parsedContent = textData.content
                }
            } else {
                console.warn('No content field found for text ID:', textId)
                // Inicializa o conteúdo vazio
                await updateDoc(textRef, {
                    content: JSON.stringify([{
                        id: "initial-block",
                        type: "paragraph",
                        props: {
                            textColor: "default",
                            backgroundColor: "default",
                            textAlignment: "left"
                        },
                        content: [],
                        children: []
                    }]),
                    updated_at: serverTimestamp()
                })
            }
            
            console.log('Parsed content ready, returning data')
            
            // Remove o content do textData para evitar duplicação
            // O textData é usado para metadados, parsedContent para o conteúdo do editor
            const { content, ...cleanTextData } = textData
            
            return { 
                success: true, 
                data: { textData: cleanTextData, parsedContent } 
            }
        } catch (err) {
            console.error('Error getting text:', err)
            return { success: false, error: 'Failed to load text. Please try again later.' }
        }
    }

    createNewText() {
        try {
            // Cria um novo texto vazio com timestamp
            const now = new Date()
            
            const newText = {
                title: 'Untitled Text',
                theme: '',
                summary: '',
                isPriority: false,
                created_at: now,
                updated_at: now
            }
            
            // O conteúdo inicial é um array com um bloco vazio
            // Isso evita o erro "initialContent must be a non-empty array of blocks"
            const initialContent = [
                {
                    id: "initial-block",
                    type: "paragraph",
                    props: {
                        textColor: "default",
                        backgroundColor: "default",
                        textAlignment: "left"
                    },
                    content: [],
                    children: []
                }
            ]
            
            return {
                success: true,
                data: {
                    text: newText,
                    content: initialContent
                }
            }
        } catch (err) {
            console.error('Error creating new text:', err)
            return { success: false, error: 'Failed to create new text' }
        }
    }

    async publishText(textData, contentData, currentTexts) {
        try {
            console.log('Publishing text with data:', textData)
            console.log('Content data length:', contentData ? contentData.length : 0)
            
            // Garantir que o conteúdo seja um array, mesmo que vazio
            const safeContent = contentData || []
            
            // Se o conteúdo estiver vazio, adicionar um bloco padrão para evitar erros
            if (safeContent.length === 0) {
                safeContent.push({
                    id: "default-block",
                    type: "paragraph",
                    props: {
                        textColor: "default",
                        backgroundColor: "default",
                        textAlignment: "left"
                    },
                    content: [],
                    children: []
                })
            }
            
            // Se o texto já tem um ID, é uma atualização
            if (textData.id) {
                // Atualiza o documento incluindo o conteúdo
                const textRef = doc(db, 'bibliotheca', textData.id)
                await updateDoc(textRef, {
                    title: textData.title,
                    theme: textData.theme || '',
                    summary: textData.summary || '',
                    isPriority: textData.isPriority || false,
                    content: JSON.stringify(safeContent),
                    updated_at: serverTimestamp()
                })
                
                // Buscar o documento atualizado para obter o timestamp atualizado
                const updatedDoc = await getDoc(textRef)
                const updatedData = updatedDoc.data()
                
                // Atualiza a lista local
                const updatedTexts = currentTexts.map(text => {
                    if (text.id === textData.id) {
                        return {
                            ...textData,
                            updated_at: updatedData.updated_at
                        }
                    }
                    return text
                })
                
                console.log('Text updated successfully')
                
                return {
                    success: true,
                    data: {
                        texts: updatedTexts,
                        selectedText: {
                            ...textData,
                            updated_at: updatedData.updated_at
                        }
                    }
                }
            }
            // Caso contrário, é um novo texto
            else {
                // Criar novo documento na coleção bibliotheca
                const textsCollection = collection(db, 'bibliotheca')
                const newTextRef = doc(textsCollection)
                
                const newTextData = {
                    title: textData.title,
                    theme: textData.theme || '',
                    summary: textData.summary || '',
                    isPriority: textData.isPriority || false,
                    content: JSON.stringify(safeContent),
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                }
                
                await setDoc(newTextRef, newTextData)
                
                // Buscar o documento criado para obter os timestamps
                const createdDoc = await getDoc(newTextRef)
                const createdData = createdDoc.data()
                
                // Adicionar à lista local
                const newText = {
                    id: newTextRef.id,
                    ...textData,
                    created_at: createdData.created_at,
                    updated_at: createdData.updated_at,
                    content: undefined // Não duplicar o conteúdo na lista
                }
                
                console.log('New text created successfully with ID:', newTextRef.id)
                
                return {
                    success: true,
                    data: {
                        texts: [newText, ...currentTexts],
                        selectedText: newText
                    }
                }
            }
        } catch (err) {
            console.error('Error publishing text:', err)
            return { success: false, error: 'Failed to publish text. Please try again later.' }
        }
    }

    async deleteText(textId, currentTexts) {
        try {
            // Excluir o documento
            const textRef = doc(db, 'bibliotheca', textId)
            await deleteDoc(textRef)
            
            // Atualizar a lista local
            const updatedTexts = currentTexts.filter(text => text.id !== textId)
            
            return {
                success: true,
                data: {
                    texts: updatedTexts
                }
            }
        } catch (err) {
            console.error('Error deleting text:', err)
            return { success: false, error: 'Failed to delete text. Please try again later.' }
        }
    }

    getAllThemes(texts) {
        if (!texts || texts.length === 0) return []
        return [...new Set(texts.filter(text => text.theme).map(text => text.theme))]
    }

    groupTextsByTheme(texts) {
        if (!texts || texts.length === 0) return {}
        
        return texts.reduce((acc, text) => {
            const theme = text.theme || 'Uncategorized'
            if (!acc[theme]) {
                acc[theme] = []
            }
            acc[theme].push(text)
            return acc
        }, {})
    }
}

export default new BibliothecaController()