import React from 'react'
import styled from 'styled-components'
import { RowCSS } from '../components/BasicComponents'
import Shelf from './../assets/shelf.png'
import colors from '../osler-components/Colors'

const Header = styled.div`
    ${RowCSS}
    width: 100%;
    justify-content: space-between;
    padding: 2em;
`

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

const LogoIcon = styled.img`
    height: 36px;
    width: auto;
`

const Logo = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    color: ${colors.black};
    margin: 0;
    letter-spacing: -0.5px;
`

const TabsContainer = styled.div`
    display: flex;
    gap: 30px;
    position: relative;
`

const Tab = styled.button`
    background: none;
    border: none;
    font-size: 1rem;
    color: ${props => props.active ? colors.primaryBlue : colors.darkGray};
    font-weight: ${props => props.active ? '600' : '400'};
    cursor: pointer;
    padding: 5px 0;
    position: relative;
    transition: all 0.2s ease;
    
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: ${props => props.active ? '100%' : '0'};
        height: 2px;
        background: ${props => props.active ? colors.primaryBlue : 'transparent'};
        transition: width 0.3s ease;
    }
    
    &:hover {
        color: ${colors.primaryBlue};
    }
    
    &:hover::after {
        width: 100%;
        background: ${colors.primaryBlue};
        opacity: ${props => props.active ? '1' : '0.5'};
    }
`

export default function BibliothecaHeader({ activeTheme, themes, onThemeChange }) {
    return (
        <Header>
            <LogoContainer>
                <LogoIcon src={Shelf} alt="Bibliotheca shelf icon" />
                <Logo>
                    Bibliotheca Osleriana
                </Logo>
            </LogoContainer>
            <TabsContainer>
                {themes.map((theme) => (
                    <Tab 
                        key={theme} 
                        active={activeTheme === theme}
                        onClick={() => onThemeChange(theme)}
                    >
                        {theme}
                    </Tab>
                ))}
            </TabsContainer>
        </Header>
    )
}