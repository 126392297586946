// Março, 2025
// Header utilizado nas páginas públicas do website

import React from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { RowCSS } from '../components/BasicComponents'
import OslerImg from './../assets/Logo.png'
import SignatureImg from './../assets/signature.png'
import Button from '../osler-components/Button'
import colors from '../osler-components/Colors'

const Header = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    background-color: ${colors.minimalGray};
`

const HeaderLogo = styled.div`
    ${RowCSS}
    cursor: pointer;
`

const Osler = styled.img`
    height: 2.5em;
`

const Signature = styled.img`
    height: 1.8em;
    margin-left: 1em;
`

const Buttons = styled.div`
    ${RowCSS}
    gap: 0.5em;
`

export default function PublicHeader() {
    const location = useLocation()
    const navigate = useNavigate()
    const currentPath = location.pathname
    
    // Função para navegar para uma rota
    const handleNavigation = (path) => {
        navigate(path)
    }

    // Determinar a página atual
    const isHome = currentPath === '/' || currentPath === '/home'
    const isBibliotheca = currentPath.includes('/bibliotheca')
    const isApp = currentPath.includes('/app')

    return (
        <Header>
            <HeaderLogo onClick={() => handleNavigation('/')}>
                <Osler src={OslerImg} />
                <Signature src={SignatureImg} />
            </HeaderLogo>

            <Buttons>
                {/* Quando estiver na Home: mostrar Bibliotheca e App */}
                {isHome && (
                    <>
                        <Button 
                            size='small' 
                            variant='secondary'
                            onClick={() => handleNavigation('/bibliotheca')}
                        >
                            Bibliotheca
                        </Button>

                        <Button 
                            size='small'
                            onClick={() => handleNavigation('/app')}
                        >
                            App
                        </Button>
                    </>
                )}
                
                {/* Quando estiver na Bibliotheca: mostrar Home e App */}
                {isBibliotheca && (
                    <>
                        <Button 
                            size='small' 
                            variant='secondary'
                            onClick={() => handleNavigation('/home')}
                        >
                            Inicial
                        </Button>
                        <Button 
                            size='small'
                            onClick={() => handleNavigation('/app')}
                        >
                            App
                        </Button>
                    </>
                )}
                    
            </Buttons>
        </Header>
    )
}