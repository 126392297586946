
// MentorshipScreen.jsx
import React, { useState, useEffect, useMemo } from 'react'
import Class from './Class'
import styled from 'styled-components'
import OslerCard from '../components/OslerCard'



const ScreenWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`

const ClassesContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 20px;
`

const LoadingText = styled.div`
  text-align: center;
  font-size: 14px;
  color: #888;
  padding: 40px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
`

const AccessDeniedMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: #ff4444;
    padding: 20px;
    background: #ffe6e6;
    border-radius: 8px;
    margin: 20px 0;
`;



export default function LatchKeyersScreen({hasAccess = false}) {

    const [classes, setClasses] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {

    }, [])

    return (
        <OslerCard>
            <p>Opa, ainda não há nada aqui!</p>

            <p>Se você é aluno da Latch Keyers, o mentor entrará em contato com você por WhatsApp, o que pode demorar até 5 dias após a compra para garantirmos o <i>match</i> — a mentoria realmente é 1:1 e com exclusividade.</p>
        </OslerCard>
    )
}