import React from 'react'
import styled from 'styled-components'
import colors from '../osler-components/Colors'

const Card = styled.div`
    background: ${colors.white};
    border-radius: 8px;
    transition: all 0.2s ease;
    box-shadow: ${props => props.isPriority 
        ? '0 2px 6px rgba(51, 51, 51, 0.08)' 
        : '0 1px 3px rgba(51, 51, 51, 0.05)'};
    border: 1px solid ${props => props.isPriority ? colors.burgundy : colors.gray};
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
        border-color: ${props => props.isPriority ? colors.burgundyHover : colors.mediumGray};
    }
`

const CardHeader = styled.div`
    padding: 18px 20px 14px;
    border-bottom: 1px solid ${colors.gray};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${props => props.isPriority ? colors.burgundy : 'transparent'};
    border-radius: 7px 7px 0 0;
`

const Title = styled.h4`
    font-size: 1.1rem;
    font-weight: 600;
    color: ${props => props.isPriority ? colors.white : colors.darkGray};
    margin: 0;
    line-height: 1.4;
    
    ${Card}:hover & {
        color: ${props => props.isPriority ? colors.white : colors.primaryBlue};
    }
`

const PriorityMarker = styled.span`
    color: ${props => props.isPriority ? colors.white : colors.burgundy};
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 8px;
    display: ${props => props.visible ? 'block' : 'none'};
    
    &::after {
        content: '★';
    }
`

const CardContent = styled.div`
    padding: 16px 20px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
`

const SummaryContent = styled.div`
    color: ${colors.darkGray};
    line-height: 1.5;
    font-size: 0.9rem;
    margin-bottom: 16px;
    flex: 1;
`

const ReadMore = styled.div`
    color: ${colors.primaryBlue};
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    align-self: flex-end;
    
    &::after {
        content: '→';
        margin-left: 6px;
        transition: transform 0.2s ease;
    }
    
    ${Card}:hover & {
        color: ${colors.primaryBlueHover};
    }
    
    ${Card}:hover &::after {
        transform: translateX(4px);
    }
`

export default function ArticleCard({ post, onClick }) {
    if (!post || !post.id) return null
    
    const summary = post.summary || "Sem resumo disponível.";
    
    return (
        <Card 
            onClick={onClick}
            isPriority={post.isPriority}
        >
            <CardHeader isPriority={post.isPriority}>
                <Title isPriority={post.isPriority}>{post.title}</Title>
                <PriorityMarker visible={post.isPriority} isPriority={post.isPriority} />
            </CardHeader>
            
            <CardContent>
                <SummaryContent>{summary}</SummaryContent>
                <ReadMore>Leia tudo</ReadMore>
            </CardContent>
        </Card>
    )
}