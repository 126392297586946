import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from './Colors'

export default function Button({
    children,
    variant = 'contained',  // contained, outlined, secondary
    color = 'blue',
    disabled = false,
    tooltip = '',
    onClick,
    fullWidth = false,
    size = 'medium',
    icon = null,
    ...props
}) {
    const [showTooltip, setShowTooltip] = useState(false)
    
    function handleMouseEnter() {
        if (tooltip) {
            setShowTooltip(true)
        }
    }
    
    function handleMouseLeave() {
        if (tooltip) {
            setShowTooltip(false)
        }
    }
    
    return (
        <ButtonContainer fullWidth={fullWidth}>
            <StyledButton
                type="button"
                variant={variant}
                color={color}
                disabled={disabled}
                size={size}
                onClick={disabled ? undefined : onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                hasIcon={!!icon}
                {...props}
            >
                {icon && <IconWrapper>{icon}</IconWrapper>}
                {children}
            </StyledButton>
            
            {tooltip && showTooltip && (
                <Tooltip>{tooltip}</Tooltip>
            )}
        </ButtonContainer>
    )
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['contained', 'outlined', 'secondary']),
    color: PropTypes.oneOf(['blue', 'green', 'red', 'gray']),
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    icon: PropTypes.node
}

const ButtonContainer = styled.div`
    position: relative;
    display: inline-block;
    width: ${props => props.fullWidth ? '100%' : 'auto'};
`

function getSizeStyles(size) {
    switch (size) {
        case 'small':
            return `
                padding: 6px 12px;
                font-size: 12px;
                border-radius: 4px;
            `
        case 'large':
            return `
                padding: 10px 18px;
                font-size: 16px;
                border-radius: 6px;
            `
        default: // medium
            return `
                padding: 8px 16px;
                font-size: 14px;
                border-radius: 5px;
            `
    }
}

// Mapeamento das cores dos botões
const getButtonColor = (colorName) => {
    const colorMap = {
        blue: {
            main: colors.primaryBlue,
            hover: colors.primaryBlueHover,
            text: colors.white
        },
        green: {
            main: colors.green,
            hover: colors.greenHover,
            text: colors.white
        },
        red: {
            main: colors.burgundy,
            hover: colors.burgundyHover,
            text: colors.white
        },
        gray: {
            main: colors.darkGray,
            hover: '#4b5563',
            text: colors.white
        }
    };

    return colorMap[colorName] || colorMap.blue;
};

const getButtonStyles = (props) => {
    const colorConfig = getButtonColor(props.color);
    
    switch (props.variant) {
        case 'outlined':
            return `
                background: transparent;
                color: ${colorConfig.main};
                border: 1px solid ${colorConfig.main};
                
                &:hover:not(:disabled) {
                    background: ${colors.gray};
                    border-color: ${colorConfig.grayHover};
                }
                
                &:active:not(:disabled) {
                    background: ${colors.gray};
                    border-color: ${colorConfig.grayHover};
                }
            `;
        
        case 'secondary':
            return `
                background: transparent;
                color: ${colorConfig.main};
                border: none;
                box-shadow: none;
                
                &:hover:not(:disabled) {
                    background: ${colors.gray};
                    color: ${colorConfig.hover};
                }
                
                &:active:not(:disabled) {
                    background: ${colors.gray};
                    color: ${colorConfig.hover};
                }
            `;
            
        default: // contained
            return `
                background: ${colorConfig.main};
                color: ${colorConfig.text};
                border: none;
                
                &:hover:not(:disabled) {
                    background: ${colorConfig.hover};
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
                }
                
                &:active:not(:disabled) {
                    background: ${colorConfig.hover};
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
                }
            `;
    }
};

const StyledButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
    
    ${props => getSizeStyles(props.size)}
    ${props => getButtonStyles(props)}
    
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        box-shadow: none;
    }
    
    ${props => props.hasIcon && `
        padding-left: ${props.size === 'small' ? '10px' : props.size === 'large' ? '14px' : '12px'};
    `}
    
    &:active:not(:disabled) {
        transform: translateY(1px);
    }
    
    &:focus-visible {
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    }
`

const Tooltip = styled.div`
    position: absolute;
    bottom: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.darkGray};
    color: ${colors.white};
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 4px;
        border-style: solid;
        border-color: ${colors.darkGray} transparent transparent transparent;
    }
`

const IconWrapper = styled.span`
    display: inline-flex;
    margin-right: 6px;
`