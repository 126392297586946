import React from 'react'
import styled from 'styled-components'

// Styled Components
const SidePanel = styled.div`
    width: 300px;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const PanelHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2 {
        margin: 0;
        font-size: 1.2rem;
    }
`

const CreateButton = styled.button`
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #3367d6;
    }
`

const TextsList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 8px;
`

const TextItem = styled.div`
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: ${props => props.isSelected ? '#e3f2fd' : 'white'};
    border: 1px solid ${props => props.isSelected ? '#bbdefb' : '#e0e0e0'};
    position: relative;
    
    &:hover {
        background-color: ${props => props.isSelected ? '#e3f2fd' : '#f9f9f9'};
    }

    ${props => props.isPriority && `
        border-left: 4px solid #f44336;
    `}
`

const TextTitle = styled.h3`
    margin: 0 0 8px 0;
    font-size: 1rem;
    font-weight: 500;
`

const TextDate = styled.p`
    margin: 0;
    font-size: 0.8rem;
    color: #757575;
`

const TextTheme = styled.span`
    font-size: 0.8rem;
    background-color: #e0e0e0;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 8px;
    color: #616161;
`

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #757575;
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
`

// Função simples para tratar o timestamp
const formatDate = (timestamp) => {
    try {
        return timestamp.toDate().toLocaleDateString();
    } catch (err) {
        return new Date().toLocaleDateString(); // Fallback para data atual
    }
};

export default function TextsPanel({ texts, selectedText, onSelectText, onCreateNew }) {
    return (
        <SidePanel>
            <PanelHeader>
                <h2>Texts</h2>
                <CreateButton onClick={onCreateNew}>Create New</CreateButton>
            </PanelHeader>
            <TextsList>
                {texts.length === 0 ? (
                    <EmptyState>No texts found. Create your first one!</EmptyState>
                ) : (
                    texts.map(text => (
                        <TextItem
                            key={text.id}
                            onClick={() => onSelectText(text.id)}
                            isSelected={selectedText && selectedText.id === text.id}
                            isPriority={text.isPriority}
                        >
                            <TextTitle>
                                {text.title}
                                {text.theme && <TextTheme>{text.theme}</TextTheme>}
                            </TextTitle>
                            <TextDate>
                                Last updated: {formatDate(text.updated_at)}
                            </TextDate>
                        </TextItem>
                    ))
                )}
            </TextsList>
        </SidePanel>
    )
}