import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import AppContainer from '../app-container/AppContainer';
import { db } from '../firebase/firebase-setup';
import session from './../controllers/Session';
import sessionBuilder from './../controllers/SessionBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from './../redux/loadingSlice';
import { toastMsg } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';
import IsLoading from '../main/IsLoading';
import UserReviewsInfo from '../controllers/UserReviewsInfo';
import CloseIcon from './../assets/x-icon.png';
import QuestionIcon2 from './../assets/question.png';
import QuestionIconDark from './../assets/question dark.png'
import RoadmapIcon from './../assets/roadmap.png';
import firebase from 'firebase/compat/app'

import { guaranteeSubscription } from '../firebase/firebaseUtils';
import ExtensivoModule from './ExtensivoModule'

import KeyIcon from './../assets/extensivo.png'
import KeyIconMarginless from './../assets/extensivo_marginless.png'

import HydrantIcon from './../assets/hydrant.png';
import ScalpelIcon from './../assets/scalpel.png'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles';
import ExtensivoHelp from './ExtensivoHelp';
import ExtensivoReviews from './ExtensivoReviews';
import ExtensivoSchedule from './ExtensivoSchedule';
import { RowCSS } from '../components/BasicComponents';
import ScheduleButton from './ScheduleButton';
import ScreenContainerHeader from '../components/ScreenContainerHeader';
import { PredefinedSessionConfig, SessionConfig, SORT_MODES, TEST_TYPES } from '../controllers/SessionConfig';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    

    ${backgroundWhiteBlack}

    width: 80%;
    min-height: 100vh;
    padding-top: 2em;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        // Parece bobo, mas senão fica muito apertado
        width: 98%;
    }
    
`


// Estar exportando para usar na teal dos embaixadores é uma putaria, deveria ser um componente
// próprio
export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    margin-bottom: 2em;
    box-sizing: border-box;

    @media (max-width: 900px) {
        width: 90%;
    }

    @media (max-width: 500px) {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const Title = styled.div`
    width: 100%;

    background-color: ${props => props.theme.darkMode ? 'rgba(255, 71, 87, 0.8);' : '#a10d26;'};

    color: white;
    padding: 1em;
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`


const Bttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    padding-left: 0em;
    margin-top: 0.5em;
`


const Bttn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // background-color: yellow;

    margin-right: 0.1em;

    height: 2.5em;
    padding: 0 0.75em 0 0.75em;

    cursor: pointer;
    border-radius: 1em;

    &:hover {
        background-color: rgba(230, 230, 230);
    }

    img {
        height: 1.4em;
    }

    @media (max-width: 500px) {

    }   
`



export const Text = styled.p`
    padding: 0;
    margin: 0;
    font-size: 2em;
    text-align: center;
    font-family: Lora;
`

export const Icon = styled.img`
    margin-right: 1em;
    height: 2em;
`





export default function AcademicTrackMain(props) {
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkModeOn)

    const [modules, setModules] = useState(false)
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [selectedSchedule, setSchedule] = useState('extensivo')
    const schedules = useRef({})


    const dispatch = useDispatch()
    let navigate = useNavigate();


    useEffect(() => {
        console.log("AcademicTrackScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])


    useEffect(() => {
        async function start() {
            await loadData()

            // TODO 
            // No futuro, fazer o default do usuário
            changeSchedule('extensivo')
        }

        start()
    }, [])


    async function loadData() {
        if (!UserReviewsInfo.isReady()) {
            console.log("AcademicTrackMain(): exiting to /app because data not ready")
            navigate('/app')
        }
        else {
            // Só navegar não adianta, não garante interrupção da execução da função
            console.time('get do db')
            const cronogramasRef = db.collection('cronogramas')

            
            // Fazemos um único query ao invés de baixar os três documentos. Por quê?
            //    - Reduz overhead de conexão e variabilidade de latência de rede
            //    - Firestore em teoria é otimizado para poucas requisições, e pode gerar throttle
            //    - É mais rápido
            // Em teoria, isso aqui faz 01 query ao invés de 03, e deveria ser mais rápido
            const query = cronogramasRef.where(firebase.firestore.FieldPath.documentId(), 'in', ['extensivo', 'semi_extensivo', 'intensivao']);
            
            const querySnapshot = await query.get();
            
            console.timeEnd('get do db');
            
            // Processar os documentos
            const documents = {};
            querySnapshot.forEach((doc) => {
              documents[doc.id] = doc.data()
            });
    

            console.time('simulate');
            // Invertemos a ordem, porque queremos o mais recente 
            // (último do cronograma) no topo.
            schedules.current['extensivo']      = documents['extensivo']['modules'].reverse()
            schedules.current['semi_extensivo'] = documents['semi_extensivo']['modules'].reverse()
            schedules.current['intensivao']     = documents['intensivao']['modules'].reverse()
    
            for (let schedule in schedules.current) {
                for (let module of schedules.current[schedule]) {
                    for (let step of module.steps) {
                        for (let activity of step.activities) {
                            fetchActivityTests(activity, 'Flashcards')
                            fetchActivityTests(activity, 'Residencia')
                        }
                    }
                }
            }

            console.timeEnd('simulate');

            
    
            setModules(schedules.current[selectedSchedule])
            setLoadedInfo(true)

        }
    }



    function fetchActivityTests(activity, type) {
        const tagpaths = activity[type.toLowerCase() + '_tagpaths'];


        const onlyNewTestsConfig = SessionConfig.createConfig(type, {
            removePendingReviews: true,
            onlyExtensivo: true
        })

        const reviewTestsConfig  = SessionConfig.createConfig(type, {
            removeNewTests: true,
            onlyExtensivo: true,

        })


        if (tagpaths && tagpaths.length > 0) {
            const newTests    = fetchTests(tagpaths, type, onlyNewTestsConfig)
            const reviewTests = fetchTests(tagpaths, type, reviewTestsConfig)
            activity['new'    + type] = newTests
            activity['review' + type] = reviewTests
        }
    }


    function fetchTests(tagpaths, type, config) {
        return sessionBuilder.simulate({
            testType: type,
            builder: 'custom',
            selectedTagpaths: tagpaths,
            sessionConfig: config
         })
    }


    function changeSchedule(which_schedule) {
        setModules(schedules.current[which_schedule])
        setSchedule(which_schedule)
    }



    function startTestModeSession(testType, testIDs, forceShuffle = false, descriptor = 'Cronograma') {
        dispatch(setIsLoading(true))


        const ordered = testType === TEST_TYPES.FLASHCARDS

        const config = PredefinedSessionConfig.create({
            ordering: ordered ? SORT_MODES.SORT : SORT_MODES.SHUFFLE,
            detachCousins: ordered ? true : false
        })

        if (forceShuffle) {
            config.ordering = SORT_MODES.SHUFFLE
        }

        console.log('aaaaaaaaaaaaaa')
        console.log(forceShuffle)
        console.log(config)


        sessionBuilder.start({
            testType,
            builder: 'predefined',
            studyMode: 'test-mode',
            testIDs,
            sessionConfig: config,
            descriptor: descriptor
        })


        if (session.sessionSize > 0) {
            session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function startViewModeSession(testType, tagpaths, whichMode) {
        const config = SessionConfig.createPlaygroundOrConsultMode(testType)

        sessionBuilder.start({
            testType,
            builder: 'custom',
            studyMode: whichMode,
            selectedTagpaths: tagpaths,
            sessionConfig: config,
            saveAsLastSession: true,
            downloadStatistics: false,
            descriptor: 'Cronograma'
        })


        dispatch(setIsLoading(true))
        
        if (session.sessionSize > 0) {
            session.addTemporaryListener(whichMode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }


    const getScheduleContent = () => (
        <>
            <ExtensivoReviews
                modules={modules}
                startTestModeSession={startTestModeSession} />
            
            {modules && modules.map((module, index) => (
                <ExtensivoModule
                    key={module['name']}
                    name={module['name']}
                    emojis={module['emojis']}
                    steps={module['steps']}
                    startTestModeSession={startTestModeSession}
                    startViewModeSession={startViewModeSession}
                    isLatestModule={index === 0}
                />
            ))}
        </>
    )


    const tabs = useMemo(() => [
            {
                id: 'extensivo',
                label: 'Extensivo',
                icon: KeyIconMarginless,
                content: getScheduleContent()
            },
            {
                id: 'semi_extensivo',
                label: 'Semi-Extensivo',
                icon: HydrantIcon,
                content: getScheduleContent()
            },
            {
                id: 'intensivao',
                label: 'Intensivão',
                icon: ScalpelIcon,
                content: getScheduleContent()
            },
            {
                id: 'help',
                icon: darkMode ? QuestionIconDark : QuestionIcon2,
                content: <ExtensivoHelp show={true} />
            },
            {
                id: 'schedule',
                icon: RoadmapIcon,
                content: <ExtensivoSchedule show={true} />
            }
        ])


    return (
            <>
                {!loadedInfo && <IsLoading />}

                {loadedInfo && (
                    <ScreenContainerHeader
                        icon={KeyIconMarginless}
                        title="Cronogramas"
                        tabs={tabs}
                        onTabChange={(tabId) => {
                            if (['extensivo', 'semi_extensivo', 'intensivao'].includes(tabId)) {
                                changeSchedule(tabId)
                            }
                        }}
                    />
                )}

                </>
    )
}