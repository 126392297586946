import React from 'react'
import styled from 'styled-components'
import colors from '../osler-components/Colors'
import OslerBlockViewer from './OslerBlockViewer'

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    background: ${colors.white};
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.03);
    padding: 30px;
    border: 1px solid ${colors.gray};
`

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

const BackButton = styled.button`
    background: none;
    border: none;
    color: ${colors.darkGray};
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    transition: color 0.2s ease;
    
    &:hover {
        color: ${colors.primaryBlue};
    }
    
    &::before {
        content: '←';
        font-size: 1.1rem;
        transition: transform 0.2s ease;
    }
    
    &:hover::before {
        transform: translateX(-3px);
    }
`

const Header = styled.div`
    border-bottom: 1px solid ${colors.gray};
    padding-bottom: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
`

const Title = styled.h2`
    font-size: 1.6rem;
    font-weight: 600;
    color: ${colors.primaryBlue};
    margin: 0 0 10px 0;
`

const Meta = styled.div`
    font-size: 0.85rem;
    color: ${colors.mediumGray};
    display: flex;
    align-items: center;
    gap: 15px;
`

const ArticleDate = styled.span`
    display: flex;
    align-items: center;
`

const DateLabel = styled.span`
    font-weight: 500;
    margin-right: 5px;
`

const PriorityIndicator = styled.span`
    color: ${colors.burgundy};
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    
    &::before {
        content: '★';
        margin-right: 4px;
    }
`

const ContentWrapper = styled.div`
    margin-top: 15px;
`

export default function ArticleViewer({ post, onBack, formatDate }) {
    if (!post) return null
    
    let parsedContent = []
    try {
        parsedContent = JSON.parse(post.content)
    } catch (error) {
        console.error('Error parsing content for post:', post.id, error)
        parsedContent = [{ type: 'paragraph', content: [{ text: 'Erro ao carregar conteúdo' }] }]
    }
    
    return (
        <Container>
            <Navigation>
                <BackButton onClick={onBack}>
                    Voltar para lista
                </BackButton>
            </Navigation>
            
            <Header>
                <Title>
                    {post.title}
                </Title>
                <Meta>
                    <ArticleDate>
                        <DateLabel>Última atualização:</DateLabel>
                        {formatDate(post.updated_at)}
                    </ArticleDate>
                    {post.isPriority && <PriorityIndicator>Destaque</PriorityIndicator>}
                </Meta>
            </Header>
            
            <ContentWrapper>
                <OslerBlockViewer content={parsedContent} />
            </ContentWrapper>
        </Container>
    )
}