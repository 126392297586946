// Colors.js
// Março, 2025

const colors = {
    // Cores base
    white: '#ffffff',
    black: '#000000',
    
    // Escala de cinzas
    minimalGray: '#f5f5f5',      // Background mais claro, usado como fundo de páginas
    minimalGrayHover: '#ebebeb', // Hover state para minimalGray
    
    gray: '#e0e0e0',             // Cinza para elementos sobre o minimalGray, como botões
    grayHover: '#d0d0d0',        // Hover state para gray
    
    mediumGray: '#9e9e9e',       // Cinza médio para textos secundários, bordas, etc
    darkGray: '#333333',         // Cinza escuro para textos principais
    
    // Azuis primários
    primaryBlue: '#1a365d',
    primaryBlueHover: '#152c4e',
    
    // Azuis secundários
    secondaryBlue: '#3182ce',
    secondaryBlueHover: '#2b6cb0',
    
    // Cores de destaque/alerta
    burgundy: '#a01d26',
    burgundyHover: '#8a1921',
    
    green: '#2c7a44',
    greenHover: '#25693a'
}

export default colors