import React from 'react'
import styled from 'styled-components'

// Styled Components
const PublishBar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 15px;
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

const PublishButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #43a047;
    }
    
    &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
    }
`

const DeleteButton = styled.button`
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d32f2f;
    }
    
    &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
    }
`

const ConfirmationDialog = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`

const DialogContent = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`

const DialogTitle = styled.h3`
    margin: 0 0 16px 0;
    font-size: 1.2rem;
    color: #d32f2f;
`

const DialogText = styled.p`
    margin: 0 0 24px 0;
    font-size: 1rem;
    color: #616161;
`

const DialogButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
`

const CancelButton = styled.button`
    background-color: #e0e0e0;
    color: #616161;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d5d5d5;
    }
`

const ConfirmButton = styled.button`
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d32f2f;
    }
`

const VideoIdInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    outline: none;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const TitleInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    outline: none;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const DescriptionEditor = styled.textarea`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    min-height: 200px;
    resize: vertical;
    box-sizing: border-box;
    outline: none;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const FieldLabel = styled.label`
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 4px;
    color: #616161;
`

const FormSection = styled.div`
    margin-bottom: 24px;
`

const VideoPreview = styled.div`
    margin-bottom: 24px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    
    iframe {
        width: 100%;
        height: 315px;
        border: none;
    }
`

const UnsavedIndicator = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f44336;
    margin-right: 10px;
    animation: pulse 1.5s infinite;
    
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgba(244, 67, 54, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
        }
    }
`

export default function VideoEditor({
    selectedVideo,
    unsavedChanges,
    showDeleteDialog,
    onVideoFieldsChange,
    onPublish,
    onDeleteClick,
    onDeleteConfirm,
    onDeleteCancel
}) {
    const handleVideoIdChange = (e) => {
        onVideoFieldsChange('videoId', e.target.value)
    }
    
    const handleTitleChange = (e) => {
        onVideoFieldsChange('title', e.target.value)
    }
    
    const handleDescriptionChange = (e) => {
        onVideoFieldsChange('description', e.target.value)
    }
    
    return (
        <>
            <PublishBar>
                <DeleteButton 
                    onClick={onDeleteClick} 
                    disabled={!selectedVideo.id}
                >
                    Delete Video
                </DeleteButton>
                <ButtonsContainer>
                    {unsavedChanges && <UnsavedIndicator />}
                    <PublishButton onClick={onPublish} disabled={!unsavedChanges}>
                        {selectedVideo.id ? 'Save Changes' : 'Publish New Video'}
                    </PublishButton>
                </ButtonsContainer>
            </PublishBar>
            
            <FormSection>
                <FieldLabel htmlFor="video-id">Video ID</FieldLabel>
                <VideoIdInput
                    id="video-id"
                    type="text"
                    value={selectedVideo.videoId || ''}
                    onChange={handleVideoIdChange}
                    placeholder="Enter unique video ID..."
                />
            </FormSection>
            
            <FormSection>
                <FieldLabel htmlFor="video-title">Title</FieldLabel>
                <TitleInput
                    id="video-title"
                    type="text"
                    value={selectedVideo.title || ''}
                    onChange={handleTitleChange}
                    placeholder="Enter video title..."
                />
            </FormSection>
            
            {selectedVideo.videoId && (
                <VideoPreview>
                    <iframe 
                        src={`https://player.vimeo.com/video/${selectedVideo.videoId}`}
                        title={selectedVideo.title}
                        frameBorder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowFullScreen
                    />
                </VideoPreview>
            )}
            
            <FormSection>
                <FieldLabel htmlFor="video-description">Description</FieldLabel>
                <DescriptionEditor
                    id="video-description"
                    value={selectedVideo.description || ''}
                    onChange={handleDescriptionChange}
                    placeholder="Enter video description..."
                />
            </FormSection>
            
            {showDeleteDialog && selectedVideo && (
                <ConfirmationDialog>
                    <DialogContent>
                        <DialogTitle>Delete Video</DialogTitle>
                        <DialogText>
                            Are you sure you want to delete "{selectedVideo.title}"? 
                            This action cannot be undone.
                        </DialogText>
                        <DialogButtons>
                            <CancelButton onClick={onDeleteCancel}>Cancel</CancelButton>
                            <ConfirmButton onClick={onDeleteConfirm}>Delete</ConfirmButton>
                        </DialogButtons>
                    </DialogContent>
                </ConfirmationDialog>
            )}
        </>
    )
}