import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import VideoPlayer from './VideoPlayer'
import { functions } from '../firebase/firebase-setup'
import OslerCard from '../components/OslerCard'
import ClassFeedback from './ClassFeedback'

// Container principal da aula
const ClassWrapper = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
`

// Cabeçalho com título
const Header = styled.div`
  margin-bottom: 16px;
`

const Title = styled.h1`
  font-size: 22px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
`

// Box da descrição
const DescriptionBox = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  font-size: 15px;
  color: #444;
  line-height: 1.6;
  & > p {
    margin: 0 0 12px;
  }
  & > p:last-child {
    margin-bottom: 0;
  }
  a {
    color: #007aff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

// // Container do vídeo e botão
const ContentWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 800px;
`

// Botão pra avaliar a aula
const EvaluateButton = styled.a`
  display: inline-block;
  margin-top: 16px;
  padding: 10px 20px;
  background: #007aff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  text-decoration: none;
  transition: background 0.2s ease;
  &:hover {
    background: #005bb5;
  }
`

// Mensagens de erro e loading
const ErrorMessage = styled.div`
  color: #ff3b30;
  font-size: 14px;
  padding: 12px;
  background: #fff5f5;
  border-radius: 6px;
  margin: 16px 0;
`

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #888;
  font-size: 14px;
`

export default function Class({ videoId, title, description }) {
  const [videoData, setVideoData] = useState(null)
  const [iframeSrc, setIframeSrc] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    let isMounted = true

    async function loadVideo() {
      try {
        const getVideo = functions.httpsCallable('getVdoCipherCredentials')
        const response = await getVideo({ videoId })

        if (isMounted) {
          console.log(response.data)

          setVideoData(response.data)
          setIframeSrc(
            `https://player.vdocipher.com/v2/?otp=${response.data.otp}&playbackInfo=${response.data.playbackInfo}`
          )

          console.log(`https://player.vdocipher.com/v2/?otp=${response.data.otp}&playbackInfo=${response.data.playbackInfo}`)

        }
      } catch (err) {
        if (isMounted) {
          setError('Erro ao carregar o vídeo.')
          console.error(err)
        }
      }
    }

    loadVideo()
    return () => {
      isMounted = false
    }
  }, [videoId])

  return (
    <OslerCard>
      <Header>
        <Title>{title}</Title>
      </Header>
      <ContentWrapper>
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : iframeSrc ? (
          <>
            <VideoPlayer src={iframeSrc} />
            {/* <EvaluateButton href="#">Avaliar esta aula</EvaluateButton> */}
          </>
        ) : (
          <LoadingSpinner>Carregando aula...</LoadingSpinner>
        )}
      </ContentWrapper>

      <DescriptionBox>{description}</DescriptionBox>

      {!error &&
        <ClassFeedback
          classTitle={title}
          classId={videoId} />
      }



    </OslerCard>
  )
}