import "@blocknote/core/fonts/inter.css"
import { BlockNoteView } from "@blocknote/mantine"
import "@blocknote/mantine/style.css"
import { useCreateBlockNote } from "@blocknote/react"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../firebase/firebase-setup"
import { locales } from "@blocknote/core"
import styled from "styled-components"

// Componente estilizado para garantir que o caret seja visível
const EditorWrapper = styled.div`
    /* Garantir que o tema do editor seja aplicado corretamente */
    --blocknote-selection-color: rgba(66, 133, 244, 0.3);
    --blocknote-caret-color: black;
    
    /* Garantir que o editor ocupe todo o espaço disponível */
    width: 100%;
    height: 100%;
    min-height: 300px;
    
    /* Estilo para o caret */
    & .bn-container [contenteditable="true"] {
        caret-color: var(--blocknote-caret-color);
        outline: none;
    }
    
    /* Garantir que o cursor de texto apareça quando passar por cima do conteúdo editável */
    & .bn-container [contenteditable="true"]:hover {
        cursor: text;
    }
    
    /* Garantir espaço suficiente no editor para conteúdo */
    & .bn-container {
        min-height: 100%;
    }
`

export default function OslerBlockEditor({ onContentChange, initialContent, isSavingPublicFiles = false }) {
    const editor = useCreateBlockNote({
        initialContent: initialContent || undefined, // Carrega conteúdo inicial, se fornecido    
        dictionary: locales.pt,
        uploadFile: async (file) => {
            try {
                // Criar referência no Firebase Storage
                const storageRef = ref(storage, `images/${Date.now()}_${file.name}`)

                // Configurar metadados para tornar o arquivo público (se necessário)
                const metadata = isSavingPublicFiles ? { 
                    cacheControl: 'public,max-age=31536000', // Cache público por 1 ano
                    contentDisposition: 'inline' // Exibir no navegador
                } : {}

                // Fazer upload da imagem com metadados
                const snapshot = await uploadBytes(storageRef, file, metadata)
                
                // Obter URL pública da imagem
                const url = await getDownloadURL(snapshot.ref)
                
                return url // Retorna a URL para o BlockNote
            } catch (error) {
                console.error("Erro ao fazer upload da imagem:", error)
                throw error
            }
        }
    })

    const handleChange = () => {
        onContentChange(editor.document)
    }

    return (
        <EditorWrapper>
            <BlockNoteView
                editor={editor}
                onChange={handleChange}
            />
        </EditorWrapper>
    )
}