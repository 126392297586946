import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import OslerBlockEditor from '../bibliotheca/OslerBlockEditor'

// Styled Components
const PublishBar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 15px;
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

const PublishButton = styled.button`
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #43a047;
    }
    
    &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
    }
`

const DeleteButton = styled.button`
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d32f2f;
    }
    
    &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
    }
`

const ConfirmationDialog = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`

const DialogContent = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`

const DialogTitle = styled.h3`
    margin: 0 0 16px 0;
    font-size: 1.2rem;
    color: #d32f2f;
`

const DialogText = styled.p`
    margin: 0 0 24px 0;
    font-size: 1rem;
    color: #616161;
`

const DialogButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
`

const CancelButton = styled.button`
    background-color: #e0e0e0;
    color: #616161;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d5d5d5;
    }
`

const ConfirmButton = styled.button`
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #d32f2f;
    }
`

const TitleInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    outline: none;

    caret-color: black;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const SummaryInput = styled.textarea`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    outline: none;
    min-height: 80px;
    resize: vertical;

    caret-color: black;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const ThemeInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    outline: none;

    caret-color: black;
    
    &:focus {
        border-color: #4285f4;
        box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
    }
    
    &::placeholder {
        color: #757575;
    }
`

const PriorityCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    
    input {
        margin-right: 8px;
        cursor: pointer;
    }
    
    label {
        font-size: 1rem;
        cursor: pointer;
    }
`

const EditorContainer = styled.div`
    border: 1px solid ${props => props.hasUnsavedChanges ? '#ffa726' : '#e0e0e0'};
    border-radius: 8px;
    overflow: hidden;
    ${props => props.hasUnsavedChanges && `
        box-shadow: 0 0 0 2px rgba(255, 167, 38, 0.2);
    `}
`

const UnsavedIndicator = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f44336;
    margin-right: 10px;
    animation: pulse 1.5s infinite;
    
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgba(244, 67, 54, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
        }
    }
`

export default function TextEditor({
    selectedText,
    localContent,
    unsavedChanges,
    showDeleteDialog,
    onTextFieldsChange,
    onLocalSave,
    onPublish,
    onDeleteClick,
    onDeleteConfirm,
    onDeleteCancel
}) {

    const editorIdRef = useRef()

    // Only update the ID reference when selectedText.id actually changes
    useEffect(() => {
        if (selectedText.id) {
            editorIdRef.current = selectedText.id
        } else {
            console.log('Mudamos o ID')
            editorIdRef.current = `new-${Date.now()}`
        }
    }, [selectedText.id])


    const handleTitleChange = (e) => {
        onTextFieldsChange('title', e.target.value)
    }
    
    const handleSummaryChange = (e) => {
        onTextFieldsChange('summary', e.target.value)
    }
    
    const handleThemeChange = (e) => {
        onTextFieldsChange('theme', e.target.value)
    }
    
    const handlePriorityChange = (e) => {
        onTextFieldsChange('isPriority', e.target.checked)
    }

    return (
        <>
            <PublishBar>
                <DeleteButton 
                    onClick={onDeleteClick} 
                    disabled={!selectedText.id}
                >
                    Delete Text
                </DeleteButton>
                <ButtonsContainer>
                    {unsavedChanges && <UnsavedIndicator />}
                    <PublishButton onClick={onPublish} disabled={!unsavedChanges}>
                        {selectedText.id ? 'Publish Changes' : 'Publish New Text'}
                    </PublishButton>
                </ButtonsContainer>
            </PublishBar>
            <TitleInput
                type="text"
                value={selectedText.title}
                onChange={handleTitleChange}
                placeholder="Enter title here..."
            />
            <SummaryInput
                value={selectedText.summary || ''}
                onChange={handleSummaryChange}
                placeholder="Enter a short summary (TLDR)..."
            />
            <ThemeInput
                type="text"
                value={selectedText.theme || ''}
                onChange={handleThemeChange}
                placeholder="Enter theme (optional)..."
            />
            <PriorityCheckbox>
                <input
                    type="checkbox"
                    id="priority-checkbox"
                    checked={selectedText.isPriority || false}
                    onChange={handlePriorityChange}
                />
                <label htmlFor="priority-checkbox">Mark as priority</label>
            </PriorityCheckbox>
            <EditorContainer hasUnsavedChanges={unsavedChanges}>
                <OslerBlockEditor
                    key={`editor-${editorIdRef.current}`}
                    onContentChange={onLocalSave}
                    initialContent={localContent}
                    isSavingPublicFiles={true}
                />
            </EditorContainer>
            
            {showDeleteDialog && selectedText && (
                <ConfirmationDialog>
                    <DialogContent>
                        <DialogTitle>Delete Text</DialogTitle>
                        <DialogText>
                            Are you sure you want to delete "{selectedText.title}"? 
                            This action cannot be undone.
                        </DialogText>
                        <DialogButtons>
                            <CancelButton onClick={onDeleteCancel}>Cancel</CancelButton>
                            <ConfirmButton onClick={onDeleteConfirm}>Delete</ConfirmButton>
                        </DialogButtons>
                    </DialogContent>
                </ConfirmationDialog>
            )}
        </>
    )
}