import React, { useEffect, useRef, useState } from 'react'
import TestScreenButton from '../tests/TestScreenButton'
import SupportIcon from '../assets/headphones.png'
import StickyIcon from './../assets/sticky-note.png'
import iPadIcon from './../assets/personal_note.png'
import LikeButton from '../components/LikeButton'
import BuryButton from '../components/BuryButton'
import MCQAnswerJSX from '../tests/McqAnswerJSX'
import PersonalNoteJSX from '../tests/PersonalNoteJSX'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setShowNotebook } from '../redux/notebookSlice'
import FeedbackArea from '../tests/FeedbackArea'
import NotebookController from '../controllers/NotebookController'
import { tagSequenceToPath } from '../utils/Utils'
import FlashcardAnswerJSX from './../tests/FlashcardAnswerJSX'

const CardListRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;


    margin-bottom: 5em;

    @media (max-width: 900px) {
        flex-direction: column;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`


const CardListButtons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 10px;
    align-items: center;
    align-self: center;

    margin-top: 0.5em;

    @media (max-width: 800px) {
        // Respectivamente, aproxima do card ao qual pertence, e afasta do próximo
        margin-top: 0.5em;
        margin-bottom: 1em;
    }

    @media (max-width: 500px) {
        margin-top: 0.9em;
        margin-bottom: 1em;
    }
`


export default function CardListElement({key, index, test, like, bury, feedback, postIt, journal, testType}) {

    const [showPersonalNote, setShowPersonalNote] = useState(false)
    const [givingFeedback, setGivingFeedback] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        if (test.personalNote) {
            setShowPersonalNote(true)
        }

    }, [test])

    return (
        <CardListRow key = {key} index = {index} >
            {testType === 'Flashcards' && (
                
                <FlashcardAnswerJSX test = {test} />
            )}

            {testType === 'Residencia' && (
                <MCQAnswerJSX
                    // style = {{
                    //     backgroundColor: index % 2 == 0 ? 'white' : 'rgba(240, 240, 240, 0.8)',
                    //     boxShadow: 'none',
                    //     padding: '2em',
                    //     // Precisa ter o borderRadius aqui, mas também no CardListRow
                    //     borderRadius: '1em',
                    // }}
                    test={test}
                    consultMode={true} />
            )}

    
            <CardListButtons>
                { like && 
                    <LikeButton
                        ID={test.testID}
                        testType={testType} />
                }
                { bury &&
                    <BuryButton
                        ID={test.testID}
                        testType={testType}
                        shouldShowDialog={false} />
                }

                { feedback && !givingFeedback && 
                    <TestScreenButton
                        text='Feedback'
                        action={() => setGivingFeedback(true)}
                        icon={SupportIcon} />
                }

            </CardListButtons>

            <CardListButtons>
                { postIt && !showPersonalNote && 
                    <TestScreenButton
                        text='Anotação'
                        action={() => setShowPersonalNote(true)}
                        icon={StickyIcon} />
                }
                { journal &&
                    <TestScreenButton
                        text = 'Resumo'
                        action = {() => {
                            const tagpath = tagSequenceToPath(test.tags)
                            NotebookController.saveTagpathRecommendation(tagpath)
                            dispatch(setShowNotebook(true))}
                        }
                        icon = {iPadIcon} />
                }
            </CardListButtons>

            { showPersonalNote && !givingFeedback && 
                <PersonalNoteJSX
                    close = {() => setShowPersonalNote(false)}
                    test = {test}
                    style = {{width: '80%', alignSelf: 'center'}} />
            }

            { givingFeedback && 
                <FeedbackArea
                    test = {test}
                    testType = {testType}
                    close = {() => setGivingFeedback(false)} />
            }


        </CardListRow>
    )    
}