import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OslerBlockEditor from '../bibliotheca/OslerBlockEditor'
import BibliothecaController from '../bibliotheca/BibliothecaController'
import TextsPanel from './TextsPanel'
import TextEditor from './TextEditor'

// Styled Components
const DashboardContainer = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
`

const MainContent = styled.div`
    flex: 1;
    padding: 20px;
    min-width: 500px;
    overflow-y: auto;
`

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #757575;
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
`

const LoadingMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #616161;
`

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #d32f2f;
    text-align: center;
    padding: 0 20px;
`

export default function BibliothecaDashboard() {
    const [texts, setTexts] = useState([])
    const [selectedText, setSelectedText] = useState(null)
    const [localContent, setLocalContent] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const user = useSelector(state => state.user.data)
    const navigate = useNavigate()

    useEffect(() => {
        const loadTexts = async () => {
            try {

                const authorizedUserIds = ['5TkhVQXzmaXBGaGC3sA1mT0upsq1', 'UVk85cjiR8Y6DVjL4C2C5pd32LJ3', 'BCb21hdF9vMOLFwDXgPPlfXEHpN2'];
            
                // Check if current user's ID is in the authorized list
                if (!authorizedUserIds.includes(user.id)) {
                    console.log('Missing permission');
                    navigate('/app');
                    return;
                }
                
                const result = await BibliothecaController.fetchAllTexts()
                
                if (result.success) {
                    setTexts(result.data)
                } else {
                    setError(result.error)
                }
                
                setLoading(false)
            } catch (err) {
                console.error('Error in Dashboard:', err)
                setError('An unexpected error occurred')
                setLoading(false)
            }
        }

        loadTexts()
    }, [user.id, navigate])

    const handleSelectText = async (textId) => {
        if (unsavedChanges && !window.confirm('You have unsaved changes. Discard them?')) {
            return
        }
        
        try {
            // Primeiro limpar o estado atual antes de carregar o novo texto
            setSelectedText(null)
            setLocalContent(null)
            setLoading(true)
            setError(null) // Resetar qualquer erro anterior
            
            console.log('Requesting text with ID:', textId)
            const result = await BibliothecaController.getTextById(textId)
            
            if (result.success) {
                console.log('Text loaded successfully:', result.data.textData.title)
                console.log('Content array length:', result.data.parsedContent ? result.data.parsedContent.length : 0)
                
                const { textData, parsedContent } = result.data
                // Garantir que parsedContent seja um array mesmo que seja null ou undefined
                const safeContent = Array.isArray(parsedContent) ? parsedContent : []
                
                setSelectedText(textData)
                setLocalContent(safeContent)
                setUnsavedChanges(false)
                setLoading(false)
            } else {
                console.error('Error loading text:', result.error)
                setError(result.error)
                setLoading(false)
            }
        } catch (err) {
            console.error('Error selecting text:', err)
            setError('Failed to load selected text')
            setLoading(false)
        }
    }

    const handleLocalSave = (newContent) => {
        console.log('Saving content locally, blocks:', newContent ? newContent.length : 0)
        setLocalContent(newContent) // Recebe array de blocos diretamente do BlockNote
        setUnsavedChanges(true)
    }

    const handleCreateNew = () => {
        if (unsavedChanges && !window.confirm('You have unsaved changes. Discard them?')) {
            return
        }
        
        // Resetar o estado primeiro
        setSelectedText(null)
        setLocalContent(null)
        setError(null)
        setLoading(false)
        
        const result = BibliothecaController.createNewText()
        
        if (result.success) {
            console.log('New text created locally')
            setSelectedText(result.data.text)
            setLocalContent(result.data.content || [])
            setUnsavedChanges(false)
        } else {
            setError('Failed to create new text')
        }
    }

    const handlePublish = async () => {
        if (!selectedText) return
        
        try {
            setLoading(true)
            setError(null)
            
            console.log('Publishing text:', selectedText.title)
            // Garantir que localContent seja um array mesmo que seja null
            const safeContent = Array.isArray(localContent) ? localContent : []
            
            const result = await BibliothecaController.publishText(selectedText, safeContent, texts)
            
            if (result.success) {
                console.log('Text published successfully')
                setTexts(result.data.texts)
                setSelectedText(result.data.selectedText)
                setUnsavedChanges(false)
            } else {
                console.error('Error publishing:', result.error)
                setError(result.error)
            }
            
            setLoading(false)
        } catch (err) {
            console.error('Error publishing text:', err)
            setError('Failed to publish text')
            setLoading(false)
        }
    }

    const handleDeleteClick = () => {
        if (!selectedText || !selectedText.id) return
        setShowDeleteDialog(true)
    }

    const handleDeleteConfirm = async () => {
        if (!selectedText || !selectedText.id) return
        
        try {
            setLoading(true)
            setError(null)
            
            const result = await BibliothecaController.deleteText(selectedText.id, texts)
            
            if (result.success) {
                // Primeiro fechar o diálogo para evitar erros de renderização
                setShowDeleteDialog(false)
                
                setTexts(result.data.texts)
                
                // Limpar estados
                setSelectedText(null)
                setLocalContent(null)
                setUnsavedChanges(false)
            } else {
                setError(result.error)
                setShowDeleteDialog(false)
            }
            
            setLoading(false)
        } catch (err) {
            console.error('Error deleting text:', err)
            setError('Failed to delete text')
            setLoading(false)
            setShowDeleteDialog(false)
        }
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleTextFieldsChange = (field, value) => {
        setSelectedText({ ...selectedText, [field]: value })
        setUnsavedChanges(true)
    }

    if (loading && texts.length === 0) {
        return <LoadingMessage>Loading texts...</LoadingMessage>
    }

    if (error && texts.length === 0) {
        return <ErrorMessage>{error}</ErrorMessage>
    }

    return (
        <DashboardContainer>
            <TextsPanel 
                texts={texts}
                selectedText={selectedText}
                onSelectText={handleSelectText}
                onCreateNew={handleCreateNew}
            />
            <MainContent>
                {selectedText ? (
                    loading ? (
                        <LoadingMessage>Loading text content...</LoadingMessage>
                    ) : error ? (
                        <ErrorMessage>{error}</ErrorMessage>
                    ) : (
                        <TextEditor
                            selectedText={selectedText}
                            localContent={localContent}
                            unsavedChanges={unsavedChanges}
                            showDeleteDialog={showDeleteDialog}
                            onTextFieldsChange={handleTextFieldsChange}
                            onLocalSave={handleLocalSave}
                            onPublish={handlePublish}
                            onDeleteClick={handleDeleteClick}
                            onDeleteConfirm={handleDeleteConfirm}
                            onDeleteCancel={handleDeleteCancel}
                        />
                    )
                ) : (
                    <EmptyState>
                        {loading ? "Loading editor..." : "Select a text to edit or create a new one"}
                    </EmptyState>
                )}
            </MainContent>
        </DashboardContainer>
    )
}