import React from 'react'
import styled from 'styled-components'

// Styled Components
const SidePanel = styled.div`
    width: 300px;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const PanelHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2 {
        margin: 0;
        font-size: 1.2rem;
    }
`

const CreateButton = styled.button`
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
        background-color: #3367d6;
    }
`

const VideosList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 8px;
`

const VideoItem = styled.div`
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: ${props => props.isSelected ? '#e3f2fd' : 'white'};
    border: 1px solid ${props => props.isSelected ? '#bbdefb' : '#e0e0e0'};
    position: relative;
    
    &:hover {
        background-color: ${props => props.isSelected ? '#e3f2fd' : '#f9f9f9'};
    }
`

const VideoTitle = styled.h3`
    margin: 0 0 8px 0;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const VideoDate = styled.p`
    margin: 0;
    font-size: 0.8rem;
    color: #757575;
`

const VideoID = styled.p`
    margin: 4px 0 0 0;
    font-size: 0.75rem;
    color: #9e9e9e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const EmptyState = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #757575;
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
`

export default function VideosListComponent({ videos, selectedVideo, onSelectVideo, onCreateNew }) {
    return (
        <SidePanel>
            <PanelHeader>
                <h2>Videos</h2>
                <CreateButton onClick={onCreateNew}>Create New</CreateButton>
            </PanelHeader>
            <VideosList>
                {videos.length === 0 ? (
                    <EmptyState>No videos found. Create your first one!</EmptyState>
                ) : (
                    videos.map(video => (
                        <VideoItem
                            key={video.id}
                            onClick={() => onSelectVideo(video.id)}
                            isSelected={selectedVideo && selectedVideo.id === video.id}
                        >
                            <VideoTitle>{video.title}</VideoTitle>
                            <VideoID>ID: {video.videoId}</VideoID>
                            <VideoDate>
                                Last updated: {video.updated_at instanceof Date 
                                    ? video.updated_at.toLocaleDateString() 
                                    : video.updated_at.toDate().toLocaleDateString()}
                            </VideoDate>
                        </VideoItem>
                    ))
                )}
            </VideosList>
        </SidePanel>
    )
}