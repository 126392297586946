import { collection, getDocs, query, orderBy, doc, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase/firebase-setup'

class AequanimitasController {
    async fetchAllVideos() {
        try {
            const videosCollection = collection(db, 'aequanimitas')
            const videosSnapshot = await getDocs(
                query(videosCollection, orderBy('updated_at', 'desc'))
            )
            const videosList = videosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            return { success: true, data: videosList }
        } catch (err) {
            console.error('Error fetching videos:', err)
            return { success: false, error: 'Failed to load videos. Please try again later.' }
        }
    }

    async getVideoById(videoId) {
        try {
            const videoRef = doc(db, 'aequanimitas', videoId)
            const videoSnapshot = await getDoc(videoRef)
            
            if (!videoSnapshot.exists()) {
                return { success: false, error: 'Video not found' }
            }
            
            const videoData = {
                id: videoSnapshot.id,
                ...videoSnapshot.data()
            }
            
            return { success: true, data: videoData }
        } catch (err) {
            console.error('Error getting video:', err)
            return { success: false, error: 'Failed to load video. Please try again later.' }
        }
    }

    async publishVideo(videoData, currentVideos) {
        try {
            // Validate required fields
            if (!videoData.videoId || !videoData.title) {
                return { 
                    success: false, 
                    error: 'Video ID and title are required fields' 
                }
            }
            
            const now = new Date()
            
            // If video already has an ID, it's an update
            if (videoData.id) {
                const videoRef = doc(db, 'aequanimitas', videoData.id)
                await updateDoc(videoRef, {
                    videoId: videoData.videoId,
                    title: videoData.title,
                    description: videoData.description || '',
                    updated_at: now
                })
                
                // Update the local list
                const updatedVideos = currentVideos.map(video => {
                    if (video.id === videoData.id) {
                        return {
                            ...videoData,
                            updated_at: now
                        }
                    }
                    return video
                })
                
                return {
                    success: true,
                    data: {
                        videos: updatedVideos,
                        selectedVideo: {
                            ...videoData,
                            updated_at: now
                        }
                    }
                }
            }
            // Otherwise, it's a new video
            else {
                const videosCollection = collection(db, 'aequanimitas')
                const newVideoRef = doc(videosCollection)
                
                const newVideoData = {
                    videoId: videoData.videoId,
                    title: videoData.title,
                    description: videoData.description || '',
                    created_at: now,
                    updated_at: now
                }
                
                await setDoc(newVideoRef, newVideoData)
                
                // Add to local list
                const newVideo = {
                    id: newVideoRef.id,
                    ...newVideoData
                }
                
                return {
                    success: true,
                    data: {
                        videos: [newVideo, ...currentVideos],
                        selectedVideo: newVideo
                    }
                }
            }
        } catch (err) {
            console.error('Error publishing video:', err)
            return { success: false, error: 'Failed to publish video. Please try again later.' }
        }
    }

    async deleteVideo(videoId, currentVideos) {
        try {
            const videoRef = doc(db, 'aequanimitas', videoId)
            await deleteDoc(videoRef)
            
            // Update local list
            const updatedVideos = currentVideos.filter(video => video.id !== videoId)
            
            return {
                success: true,
                data: {
                    videos: updatedVideos
                }
            }
        } catch (err) {
            console.error('Error deleting video:', err)
            return { success: false, error: 'Failed to delete video. Please try again later.' }
        }
    }
}

export default new AequanimitasController()