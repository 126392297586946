import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { ColumnCSS } from '../components/BasicComponents'
import colors from '../osler-components/Colors'
import BibliothecaController from './BibliothecaController'

// Componentes
import PublicHeader from './../osler-components/PublicHeader'
import BibliothecaHeader from './BibliothecaHeader'
import ArticleCard from './ArticleCard'
import ArticleViewer from './ArticleViewer'

// Container principal
const BlogContainer = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    background: ${colors.white};
    min-height: 100vh;
`

// Seções de conteúdo
const ContentSection = styled.section`
    margin-bottom: 40px;
    width: 100%;
    padding: 0 2em;
`

const ThemeTitle = styled.h3`
    font-size: 1.3rem;
    font-weight: 600;
    color: ${colors.primaryBlue};
    margin-bottom: 20px;
    padding-bottom: 8px;
    position: relative;
    display: inline-block;
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${colors.primaryBlue};
        border-radius: 1px;
    }
`

// Grid de artigos
const ArticlesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    width: 100%;
`

// Componentes de carregamento e erro
const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
`

const LoadingSpinner = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid ${colors.lightGray};
    border-top: 2px solid ${colors.primaryBlue};
    animation: spin 0.8s linear infinite;
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`

const ErrorMessage = styled.div`
    padding: 25px;
    background-color: #fee2e2;
    border-radius: 10px;
    color: ${colors.burgundy};
    font-weight: 500;
    text-align: center;
`

const EmptyState = styled.div`
    padding: 40px 0;
    text-align: center;
    color: #8a94a6;
    font-size: 1rem;
`

export default function BibliothecaOsleriana() {
    const [posts, setPosts] = useState([])
    const [activeTheme, setActiveTheme] = useState('Todos')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [allThemes, setAllThemes] = useState(['Todos'])
    const [selectedPost, setSelectedPost] = useState(null)

    const { postId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (postId && posts.length > 0) {
            const post = posts.find(p => p.id === postId)
            if (post) {
                setSelectedPost(post)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            } else {
                navigate('/bibliotheca')
            }
        } else {
            setSelectedPost(null)
        }
    }, [postId, posts, navigate])

    // Carregar dados do Firebase
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setError(null)
            
            try {
                const result = await BibliothecaController.fetchAllTexts()
                
                if (result.success) {
                    setPosts(result.data)
                    
                    // Extrair temas
                    const themes = BibliothecaController.getAllThemes(result.data)
                    setAllThemes(['Todos', ...themes])
                } else {
                    setError(result.error)
                }
            } catch (err) {
                console.error('Error in BibliothecaOsleriana:', err)
                setError('Failed to load content. Please try again later.')
            } finally {
                setLoading(false)
            }
        }
        
        fetchData()
    }, [])

    // Filtrar posts pelo tema selecionado
    const filteredPosts = activeTheme === 'Todos' 
        ? posts 
        : posts.filter(post => post.theme === activeTheme)
    
    // Agrupar posts por tema
    const groupedPosts = BibliothecaController.groupTextsByTheme(filteredPosts)

    // Manipuladores de eventos
    function handleThemeChange(theme) {
        setActiveTheme(theme)
        setSelectedPost(null)
        navigate('/bibliotheca')
    }

    function handlePostSelect(post) {
        navigate(`/bibliotheca/${post.id}`)
    }

    function handleBackToList() {
        navigate('/bibliotheca')
    }

    // Formatar a data
    function formatDate(timestamp) {
        if (!timestamp) return 'Data não disponível'
        
        try {
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp)
            return date.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            })
        } catch (error) {
            console.error('Error formatting date:', error)
            return 'Data inválida'
        }
    }

    // Renderização para estados de carregamento e erro
    if (loading) {
        return (
            <BlogContainer>
                <PublicHeader />
                <StatusContainer>
                    <LoadingSpinner />
                </StatusContainer>
            </BlogContainer>
        )
    }

    if (error) {
        return (
            <BlogContainer>
                <PublicHeader />
                <StatusContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </StatusContainer>
            </BlogContainer>
        )
    }

    // Renderização do conteúdo
    function renderContent() {
        if (selectedPost) {
            return (
                <ArticleViewer 
                    post={selectedPost} 
                    onBack={handleBackToList} 
                    formatDate={formatDate}
                />
            )
        }

        if (Object.keys(groupedPosts).length > 0) {
            return Object.entries(groupedPosts).map(([theme, themePosts]) => (
                <ContentSection key={theme}>
                    <ThemeTitle>{theme}</ThemeTitle>
                    <ArticlesGrid>
                        {themePosts.map(post => (
                            <ArticleCard 
                                key={post.id} 
                                post={post} 
                                onClick={() => handlePostSelect(post)}
                            />
                        ))}
                    </ArticlesGrid>
                </ContentSection>
            ))
        }

        return (
            <EmptyState>
                Nenhum texto encontrado. Acesse o painel administrativo para criar conteúdo.
            </EmptyState>
        )
    }

    // Renderização principal
    return (
        <BlogContainer>
            <PublicHeader />
            <BibliothecaHeader 
                activeTheme={activeTheme}
                themes={allThemes}
                onThemeChange={handleThemeChange}
            />
            {renderContent()}
        </BlogContainer>
    )
}