// MentorshipScreen.jsx
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Class from './Class'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import Mentor from './../assets/mentor.png'
import { useSelector } from 'react-redux'
import { getCurrentProduct } from '../user/checkout/CheckoutData'
import { useNavigate } from 'react-router-dom'
import { guaranteeSubscription } from '../firebase/firebaseUtils'


import AequanimitasIcon from './../assets/aequanimitas.png'
import LatchKeyersIcon from './../assets/latchKeyers.png'
import AequanimitasScreen from './AequanimitasScreen'
import LatchKeyersScreen from './LatchKeyersScreen'



export default function MentorshipScreen() {

    const [hasAccess, setHasAccess] = useState(false)
    let navigate = useNavigate()
    const user = useSelector(state => state.user.data)


    const tabs = useMemo(() => [
        {
            id: 'aequanimitas',
            label: 'Aequanimitas',
            // icon: AequanimitasIcon,
            content: <AequanimitasScreen hasAccess = {hasAccess} />
        },
        {
            id: 'latch_keyers',
            label: 'Latch Keyers',
            // icon: LatchKeyersIcon,
            content: <LatchKeyersScreen />
        },
    ])


    useEffect(() => {
        function checkHasAccess(user) {
            const userProduct = getCurrentProduct(user.subscription)
            return userProduct.includes('AEQUANIMITAS') || userProduct.includes('LATCH_KEYERS')
        }

        guaranteeSubscription(user, navigate)
        const allowed = checkHasAccess(user)
        setHasAccess(allowed)
    }, [])

    return (
            <ScreenContainerHeader title="Mentoria" icon={Mentor} tabs={tabs} />
    )
}