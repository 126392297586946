// VideoPlayer.jsx
import styled from 'styled-components'

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* Proporção 16:9 */
  height: 0;
  border-radius: 8px;
  overflow: hidden;
  background: #000;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

export default function VideoPlayer({ src }) {
  return (
    <VideoWrapper>
      {src ? (
        <StyledIframe src={src} allowFullScreen allow="autoplay; encrypted-media" />
      ) : (
        <p>Aguardando vídeo...</p>
      )}
    </VideoWrapper>
  )
}